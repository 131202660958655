import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image"

import discographyStyles from "./discographyStyles.module.scss"

import { TweenMax } from "gsap"

import Fade from "react-reveal/Fade"

import { Playable } from "../components/miscBJComponents"

import { Portal } from "../components/portal"

import AudioPlayer from "react-h5-audio-player"

let moment = require("moment")

class FullDiscographyPage extends Component {
   constructor(props) {
      super(props)
      this.hrEl = null
      this.hrTweenIn = null
      this.hrTweenOut = null

      this.clickPlayable = this.clickPlayable.bind(this)

      this.state = {
         nowPlaying:
            "https://p.scdn.co/mp3-preview/6021f5e89208bcf745aecc7aeed77376c8fea933?cid=87ee9f3be4db430ba00cc6e25968a1cf",
         preventReveal: false,
         playerHide: true,
      }
   }
   componentDidMount() {
      this.hrTweenIn = TweenMax.from(this.hrEl, 0.7, {
         width: 0,
         opacity: 0,
         paused: false,
      })
      this.hrTweenOut = TweenMax.to(this.hrEl, 0.7, {
         width: 0,
         opacity: 0,
         paused: true,
      })
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.transitionStatus === "entering") {
         this.hrTweenIn.play()
      } else if (this.props.transitionStatus === "exiting") {
         this.hrTweenOut.play()
      }
   }

   clickPlayable(clickedTrack) {
      this.setState({ nowPlaying: clickedTrack.url })
      this.setState({ preventReveal: true })
      this.setState({ playerHide: false })
   }

   render() {
      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            {
               text:
                  "Congratulations you made it all the way down here. Click here to learn about more of my projects?",
               link: "projects",
            },
            { text: "Or here to get in touch.", link: "contact" },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }

      //get the data from the graphql stuff
      let spotifyAlbums = this.props.data.allSpotifyTrack.group

      //sort by release date
      spotifyAlbums.sort(
         (a, b) =>
            b.nodes[0].track.album.release_date -
            a.nodes[0].track.album.release_date
      )

      // add whether they are albums or singles
      spotifyAlbums.map(album => {
         if (album.nodes.length === 1) album.type = "single"
         else if (album.nodes.length < 6) album.type = "ep"
         else album.type = "album"

         album.year = moment
            .unix(album.nodes[0].track.album.release_date)
            .format("YYYY")
         album.month = moment
            .unix(album.nodes[0].track.album.release_date)
            .format("MMMM")
         album.day = moment
            .unix(album.nodes[0].track.album.release_date)
            .format("Do")
         album.date = moment
            .unix(album.nodes[0].track.album.release_date)
            .format("Do MMMM YYYY")

         album.title = album.nodes[0].track.album.name
         album.artist = album.nodes[0].track.album.artists[0].name
         album.link = album.nodes[0].track.album.href
         album.artwork = album.nodes[0].track.album.images[1].url

         return album
      })

      const groupBy = key => array =>
         array.reduce((objectsByKeyValue, obj) => {
            const value = obj[key]
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(
               obj
            )
            return objectsByKeyValue
         }, {})

      const groupByYear = groupBy("year")
      let spotifyAlbumsYear = groupByYear(spotifyAlbums)
      spotifyAlbumsYear = Object.entries(spotifyAlbumsYear)

      function SingleTrack(props) {
         const track = props.track
         const artist = track.track.album.artists[0].name
         const trackURL = track.track.preview_url
         return trackURL ? (
            <li className={discographyStyles.track}>
               <Playable
                  trackURL={trackURL}
                  trackName={track.track.name}
                  artistName={artist}
                  clickPlayable={props.clickPlayable}
                  playerStyles={discographyStyles.player}
               >
                  {track.track.name}
               </Playable>
            </li>
         ) : (
            <li className={discographyStyles.track}>{track.track.name}</li>
         )
      }

      function AlbumContents(props) {
         const album = props.album
         switch (album.type) {
            case "single":
               return (
                  <div className={discographyStyles.releaseType}>Single</div>
               )
            case "album":
               return (
                  <>
                     <div className={discographyStyles.releaseType}>
                        Album containing the tracks:
                     </div>
                     <ul className={discographyStyles.tracklist}>
                        {album.nodes.map((track, key) => (
                           <SingleTrack
                              track={track}
                              key={key}
                              clickPlayable={props.clickPlayable}
                           />
                        ))}
                     </ul>
                  </>
               )
            case "ep":
               return (
                  <>
                     <div className={discographyStyles.releaseType}>
                        EP containing the tracks:
                     </div>
                     <ul className={discographyStyles.tracklist}>
                        {album.nodes.map((track, key) => (
                           <SingleTrack
                              track={track}
                              key={key}
                              clickPlayable={props.clickPlayable}
                           />
                        ))}
                     </ul>
                  </>
               )
            default:
               return null
         }
      }

      function getMostPopularTrack(album) {
         const tracks = album.nodes
         let mostPopular = ""

         if (tracks.length > 1) {
            mostPopular = tracks.reduce((prev, current) => {
               return prev.popularity > current.popularity ? prev : current
            }, tracks[0])
         } else mostPopular = tracks[0]

         return mostPopular.track
      }

      function SingleAlbum(props) {
         const album = props.album
         const isOdd = props.index % 2 === 0
         let oddOrEven = {}
         const oddClass = discographyStyles.oddRelease
         const evenClass = discographyStyles.evenRelease
         const mostPopularTrack = getMostPopularTrack(album)

         if (isOdd) {
            oddOrEven = oddClass
         } else {
            oddOrEven = evenClass
         }

         return (
            <li className={`${discographyStyles.release} ${oddOrEven}`}>
               <div className={discographyStyles.side1}>
                  <svg
                     width="50px"
                     height="8px"
                     xmlns="http://www.w3.org/2000/svg"
                     className={discographyStyles.releaseBackgroundSVG}
                  >
                     <line
                        id="svg_2"
                        x2="0"
                        y2="0"
                        y1="0"
                        x1="50px"
                        strokeWidth="12"
                        stroke="#fff"
                        fill="none"
                     />
                  </svg>
                  <img
                     src={album.artwork}
                     className={discographyStyles.artwork}
                     alt={album.title}
                  />
               </div>
               <div className={discographyStyles.side2}>
                  <time className={discographyStyles.releaseDate}>
                     {album.date}
                  </time>

                  <Playable
                     trackURL={mostPopularTrack.preview_url}
                     trackName={mostPopularTrack.name}
                     artistName={mostPopularTrack.album.artists[0].name}
                     clickPlayable={props.clickPlayable}
                     playerStyles={discographyStyles.player}
                  >
                     <h4 className={discographyStyles.albumTitle}>
                        {album.title}
                     </h4>
                  </Playable>

                  <h5 className={discographyStyles.albumArtist}>
                     {album.artist}
                  </h5>
                  <AlbumContents
                     album={album}
                     clickPlayable={props.clickPlayable}
                  />
               </div>
            </li>
         )
      }

      class AlbumsInYear extends Component {
         constructor(props) {
            super(props)

            this.state = { reveal: true }
         }

         componentDidMount() {
            if (this.props.preventReveal === true)
               this.setState({ reveal: false })
         }

         render() {
            return this.state.reveal
               ? this.props.year.map((album, index) => (
                    <Fade bottom key={index}>
                       <SingleAlbum
                          album={album}
                          index={index}
                          clickPlayable={this.props.clickPlayable}
                       />
                    </Fade>
                 ))
               : this.props.year.map((album, index) => (
                    <SingleAlbum
                       key={index}
                       album={album}
                       index={index}
                       clickPlayable={this.props.clickPlayable}
                    />
                 ))
         }
      }

      function AllAlbumsByYear(props) {
         let years = props.years.reverse()

         return (
            <ul className={discographyStyles.allReleases}>
               {years.map((year, key) => (
                  <li className={discographyStyles.allReleasesYearLI} key={key}>
                     <h3 className={discographyStyles.yearHeader}>{year[0]}</h3>
                     <svg
                        width="100%"
                        height="100%"
                        xmlns="http://www.w3.org/2000/svg"
                        className={discographyStyles.yearBackgroundSVG}
                     >
                        <line
                           id="svg_1"
                           y2="100%"
                           x2="50%"
                           y1="0"
                           x1="50%"
                           strokeWidth="8"
                           stroke="#fff"
                           fill="none"
                        />
                     </svg>
                     <ul className={discographyStyles.allReleasesYearUl}>
                        <AlbumsInYear
                           year={year[1]}
                           clickPlayable={props.clickPlayable}
                           preventReveal={props.preventReveal}
                        />
                     </ul>
                  </li>
               ))}
            </ul>
         )
      }

      const bgImageData = this.props.data.desktop.childImageSharp.fluid

      return (
         <BackgroundImage
            Tag="div"
            className={`mainContainer backgroundImage ${discographyStyles.mainContainer}`}
            fluid={bgImageData}
            style={{
               backgroundRepeat: "repeat",
               backgroundSize: "auto",
               backgroundImage:
                  "linear-gradient(180deg, #82939A 0%, #FA8787 32.81%, #A6E480 67.19%, #CD8FE2 100%)",
            }}
         >
            <Layout
               title="Full Discography"
               superTitle="Mr BJ Jackson"
               pageStyles={discographyStyles}
               animationStatus={animationStatus}
               footerContent={footerContent}
            >
               <Portal>
                  <AudioPlayer
                     hidePlayer={this.state.playerHide}
                     src={this.state.nowPlaying}
                     className="audioPlayer"
                  />
               </Portal>

               <h4 className={`${discographyStyles.introduction} subheader`}>
                  A chronological rundown of my releases to date.These releases
                  can all be found on my Spotify playlist:{" "}
                  <a
                     title="BJ on the Prodo Spotify Playlist"
                     href="https://open.spotify.com/playlist/2EwKs5HemI3fEtDznomqUs?si=-Hm5P103TqG-RcXw1edZdQ"
                  >
                     BJ on the Prodo
                  </a>
               </h4>
               <hr ref={hr => (this.hrEl = hr)} />
               <AllAlbumsByYear
                  years={spotifyAlbumsYear}
                  clickPlayable={this.clickPlayable}
                  preventReveal={this.state.preventReveal}
               />
            </Layout>
         </BackgroundImage>
      )
   }
}

export default FullDiscographyPage

export const data = graphql`
   query {
      desktop: file(relativePath: { eq: "Grain-Texture-08.png" }) {
         childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      allSpotifyTrack {
         group(field: track___album___id) {
            nodes {
               track {
                  album {
                     uri
                     name
                     images {
                        height
                        url
                        width
                     }
                     href
                     album_type
                     artists {
                        name
                        href
                     }
                     release_date(formatString: "X")
                     id
                  }
                  name
                  preview_url
                  id
                  popularity
               }
            }
         }
      }
   }
`
